import { useLogin, Login } from "react-admin";
import { Box, Button } from "@mui/material";

const LoginPage = () => {
  const login = useLogin();
  return (
    <Login>
      <Box sx={{ p: 1 }}>
        <Button variant="contained" color="primary" onClick={() => login({})}>
          Login
        </Button>
      </Box>
    </Login>
  );
};
export default LoginPage;
