import * as React from "react";
import { fetchUtils, Admin, Resource, EditGuesser } from "react-admin";
import jsonServerProvider from "ra-data-json-server";
import { UserList } from "./components/UserList";
import { UserEdit } from "./components/UserEdit";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import authProvider, { authClient } from "./authProvider";
import LoginPage from "./components/LoginPage";
import { BrowserRouter } from "react-router-dom";

const url = window.__ENV.REACT_APP_OKDOC_API_ENDPOINT;
const httpClient = async (url, options = {}) => {
  if (!options.headers) {
    options.headers = new Headers({ Accept: "application/json" });
  }
  const token = await authClient.tokenManager.get("accessToken");
  options.headers.set("Authorization", `Bearer ${token?.accessToken}`);
  return fetchUtils.fetchJson(url, options);
};
const restDataProvider = jsonServerProvider(url, httpClient);
export const dataProvider = {
  ...restDataProvider,
  updateTrial: (userId, endDate) => {
    return httpClient(`${url}/user/${userId}/trial`, {
      method: "PUT",
      body: JSON.stringify({ endDate }),
    }).then(() => ({ data: null }));
  },
};
const App = () => (
  <BrowserRouter>
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <Admin
        dataProvider={dataProvider}
        authProvider={authProvider}
        loginPage={LoginPage}
      >
        <Resource name="user" list={UserList} edit={UserEdit} />
      </Admin>
    </LocalizationProvider>
  </BrowserRouter>
);

export default App;