import {
  SelectInput,
  Edit,
  Toolbar,
  SaveButton,
  SimpleForm,
  TextInput,
  Show,
  regex,
  SimpleShowLayout,
  TextField,
  BooleanField,
  DateField,
} from "react-admin";
import { Grid, Typography } from "@mui/material";
import UserTrialUpdate from "./UserTrialUpdate";

export const UserEdit = () => (
  <>
    <Show actions={null}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
          <SimpleShowLayout>
            <Typography variant="h6">User info</Typography>
            <TextField source="id" />
            <BooleanField source="isDocumentViewAllowed" />
            <TextField source="campaignId" emptyText="-" />
            <DateField source="createdAt" />
            <DateField source="updatedAt" />
          </SimpleShowLayout>
        </Grid>
        <Grid item xs={6}>
          <SimpleShowLayout>
            <Typography variant="h6">Subscription</Typography>
            <TextField source="subscriptionStatus" emptyText="-" />
            <DateField source="trialEndAt" emptyText="-" />
            <TextField source="plan.nickname" emptyText="-" />
            <TextField source="plan.discount.name" emptyText="-" />
            <DateField source="subscriptionPeriodEndAt" emptyText="-" />
            <BooleanField source="isSubscriptionCancelingAtPeriodEnd" />
          </SimpleShowLayout>
        </Grid>
      </Grid>
    </Show>
    <Edit>
      <UserTrialUpdate />
    </Edit>
    <Edit>
      <SimpleForm
        toolbar={
          <Toolbar>
            <SaveButton />
          </Toolbar>
        }
      >
        <SelectInput
          source="honorificPrefix"
          choices={[
            { id: "M.", name: "M." },
            { id: "Mme", name: "Mme" },
          ]}
          required
        />
        <TextInput source="lastName" required />
        <TextInput source="masterEmail" validate={regex(/^\S+@\S+\.\S+$/)} />
        <TextInput source="firstName" required />
        <TextInput source="phone" required />
        <TextInput source="job" /> 
        <TextInput source="company"/>
        <TextInput source="addressLine1" />
        <TextInput source="addressLine2" />
        <TextInput source="addressPostalCode" />
        <TextInput source="addressCity" />
        
      </SimpleForm>
    </Edit>
  </>
);
