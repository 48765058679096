import {
  BooleanField,
  Datagrid,
  DateField,
  EmailField,
  List,
  Pagination,
  TextField,
  TextInput,
} from "react-admin";
const userFilters = [
  <TextInput label="Search" source="search" alwaysOn />,
];
export const UserList = () => (
  <List perPage={5} pagination={<Pagination rowsPerPageOptions={[5]} />} filters={userFilters}>
    <Datagrid rowClick="edit" bulkActionButtons={null}>
      <TextField source="id" sortable={false} />
      <EmailField source="masterEmail" sortable={false}/>
      <EmailField source="email" sortable={false} />
      <TextField source="honorificPrefix" sortable={false} />
      <TextField source="displayName" sortable={false} />
      <BooleanField source="isDocumentViewAllowed" sortable={false} />
      <TextField source="subscriptionStatus" sortable={false} />
      <DateField source="trialEndAt" sortable={false} />
      <DateField source="subscriptionPeriodEndAt" sortable={false} />
      <BooleanField
        source="isSubscriptionCancelingAtPeriodEnd"
        sortable={false}
      />
    </Datagrid>
  </List>
);
