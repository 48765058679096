import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import { useRecordContext, useDataProvider, useRefresh } from "react-admin";
import { useMutation } from "react-query";
import { DateTimePicker } from "@mui/x-date-pickers";
import { useState } from "react";
const UserTrialUpdate = () => {
  const record = useRecordContext();
  const dataProvider = useDataProvider();
  const refresh = useRefresh();
  const [value, setValue] = useState(
    record.trialEndAt ? new Date(record.trialEndAt) : null
  );
  const { mutate, isLoading } = useMutation(["updateTrial", record.id], () =>
    dataProvider.updateTrial(record.id, value).then(() => refresh())
  );

  return (
    <Box sx={{ p: 1 }}>
      <Typography variant="h6">User trial</Typography>
      <Stack direction="row" alignItems="center" spacing={2}>
        <div>
          <DateTimePicker
            label="Trial end"
            value={value}
            onChange={(v) => setValue(v)}
            renderInput={(params) => <TextField {...params} />}
          />
        </div>
        <div>
          <Button
            variant="contained"
            label="Update trial"
            onClick={() => mutate()}
            disabled={isLoading || !value}
          >
            Update trial
          </Button>
        </div>
      </Stack>{" "}
    </Box>
  );
};
export default UserTrialUpdate;
