import { OktaAuth } from "@okta/okta-auth-js";

const config = {
  issuer: window.__ENV.REACT_APP_OKTA_ISSUER,
  clientId: window.__ENV.REACT_APP_OKTA_CLIENT_ID,
  redirectUri: `${window.__ENV.REACT_APP_EXPOSED_URL}/login`,
  postLogoutRedirectUri: `${window.__ENV.REACT_APP_EXPOSED_URL}/login`,
  responseMode: "fragment",
  tokenManager: {
    storage: "sessionStorage",
  },
};

export const authClient = new OktaAuth(config);

const authProvider = {
  login: () => {
    return authClient.signInWithRedirect({
      scopes: ["openid", "profile", "email", "phone"],
    });
  },
  logout: () => {
    return authClient.signOut({ clearTokensBeforeRedirect: true });
  },
  checkAuth: async () => {
    if (authClient.isLoginRedirect()) {
      await authClient.handleLoginRedirect();
    }
    const isAuthenticated = await authClient.isAuthenticated();
    return isAuthenticated ? Promise.resolve() : Promise.reject();
  },
  checkError: async (error) => {
    const status = error.status;
    if (status === 401) {
      const isAuthenticated = await authClient.isAuthenticated();
      if (isAuthenticated) await authClient.signOut();
      return Promise.reject();
    }
    return Promise.resolve();
  },
  getIdentity: async () => {
    const user = await authClient.token.getUserInfo();
    return Promise.resolve({
      id: user.sub,
      fullName: user.name,
    });
  },
  getPermissions: () => Promise.resolve(""),
};

export default authProvider;
